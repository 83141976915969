import React from 'react';
import '../styles/Footer.css';

// <li><i className="fas fa-home"></i></li>

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo-section">
         <a href="/"><img src="/assets/images/logo.png" alt="Logo" className="footer-logo" /></a>
        </div>
        <div className="contact-section">
          <h4>CONTATTI</h4>
          <ul>
            <li><i className="fas fa-phone"></i>
            <a href="https://wa.me/393518101018" className="footerContatti">351 8101018</a>
             </li>
            <li><i className="fas fa-envelope"></i>
            <a href="mailto: pattonord@gmail.com" className="footerContatti">pattonord@gmail.com</a>
            </li>
          </ul>
        </div>
        <div className="social-section">
          <ul className="social-icons">
            <li><a href="https://x.com/Pattoperilnord"><i className="fab fa-twitter"></i></a></li>
            <li><a href="https://www.facebook.com/pattoperilnord1/"><i className="fab fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/pattoperilnord/"><i className="fab fa-instagram"></i></a></li>
            <li><a href="https://t.me/pattoperilnord"><i className="fab fa-telegram"></i></a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy;Patto per il Nord 2024  | <a href="InformativaPrivacy">Informativa privacy</a> e <a href="CookiePolicy">cookie policy</a> | <a href="">Contatti</a></p>
      </div>
    </footer>
  );
};

export default Footer;
