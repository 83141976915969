import React, { useState } from 'react'; 
import Navbar2 from '../components/Navbar2';
import Footer from '../components/Footer';
import CookiePopup from '../components/CookiePopup';
import ManifestiResource from '../components/ManifestiResource';



const Manifesti = () => {


    return (
      <div>
        <Navbar2 />
        <ManifestiResource />

        <Footer />
        
        <CookiePopup />
      </div>
    );
  };
  
  export default Manifesti;