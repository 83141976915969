const provinces = [
  { label: "Estero", options: ["Estero"]},
  { label: "Lombardia", options: ["Milano", "Bergamo", "Brescia", "Como", "Cremona", "Lecco", "Lodi", "Mantova", "Monza e Brianza", "Pavia", "Sondrio", "Varese"] },
  { label: "Veneto", options: ["Venezia", "Belluno", "Padova", "Rovigo", "Treviso", "Verona", "Vicenza"] },
  { label: "Piemonte", options: ["Torino", "Alessandria", "Asti", "Biella", "Cuneo", "Novara", "Verbano-Cusio-Ossola", "Vercelli"] },
  { label: "Liguria", options: ["Genova", "Imperia", "La Spezia", "Savona"] },
  { label: "Trentino-Alto Adige", options: ["Bolzano", "Trento"] },
  { label: "Friuli Venezia Giulia", options: ["Gorizia", "Pordenone", "Trieste", "Udine"] },
  { label: "Valle d'Aosta", options: ["Aosta"] },
  { label: "Emilia-Romagna", options: ["Bologna", "Ferrara", "Forlì-Cesena", "Modena", "Parma", "Piacenza", "Ravenna", "Reggio Emilia", "Rimini"] },
  { label: "Toscana", options: ["Arezzo", "Firenze", "Grosseto", "Livorno", "Lucca", "Massa-Carrara", "Pisa", "Pistoia", "Prato", "Siena"] },
  { label: "Marche", options: ["Ancona", "Ascoli Piceno", "Fermo", "Macerata", "Pesaro e Urbino"] },
  { label: "Umbria", options: ["Perugia", "Terni"] }
  ];
  
  export default provinces;