import React from "react";
import "../styles/HomeProvvisoria.css";

const HomeProvvisoria = () => {
  return (
    <div className="tesseramento-container">
      <div className="logo-container">
        <img
          src="../assets/images/logo.png"
          alt="Patto per il Nord Logo"
          className="logo"
        />
      </div>
      <h1 className="tesseramento-title">Tesseramento 2024/25</h1>
      <p className="tesseramento-subtitle">
       Tesserati anche tu <br /> Cliccando il tasto qui sotto
      </p>
      <a href="/tesserati"><button className="tesseramento-button">Tesserati Ora</button></a>
    </div>

    
  );
};

export default HomeProvvisoria;
