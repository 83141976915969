import React, { useState } from 'react'; 
import Navbar2 from '../components/Navbar2';
import Footer from '../components/Footer';
import ContactFormTesseramento from '../components/ContactFormTesseramento';
import '../styles/Tesserati.css';
import CookiePopup from '../components/CookiePopup';

const Tesserati = () => {


  return (
    <div>
      <Navbar2 />
      <ContactFormTesseramento />
      <Footer />
      
      <CookiePopup />
    </div>
  );
};

export default Tesserati;
