import React from "react";
import Navbar2 from "../components/Navbar2";
import Footer from "../components/Footer";
import '../styles/InformativaPrivacy.css'

const CookiePolicy = () =>{

    return(
       
    <div>
        <Navbar2 />
        <section className="paddingDiv">
            <h1>Cookie Policy</h1>
            <p><strong>Ultimo aggiornamento:</strong> 24/11/2024</p>
            <p>
                Questo sito non utilizza cookie propri per raccogliere informazioni personali o tracciare l’attività degli utenti. <br />
                Tuttavia, collegamenti o pulsanti verso piattaforme di terze parti come Facebook, Instagram, X (Twitter) e Telegram potrebbero utilizzare i propri cookie. <br />
            </p>

            <h2>Cosa sono i cookie?</h2>
            <p>
                I cookie sono piccoli file di testo inviati dai siti web al browser dell'utente e memorizzati sul dispositivo. <br />
                Questi file possono essere utilizzati per migliorare l'esperienza dell'utente o per raccogliere informazioni per scopi specifici. <br />
            </p>

            <h2>Servizi di terze parti</h2>
            <p>
                Sul nostro sito sono presenti pulsanti e collegamenti che permettono di accedere ai nostri profili social su Facebook, Instagram, X (Twitter), e Telegram. <br />
                Interagendo con questi pulsanti o collegamenti, le rispettive piattaforme potrebbero utilizzare i propri cookie per tracciare la tua attività. <br />
            </p>

            <h2>Dettagli sui cookie di terze parti</h2>
            <ol>
                <li>
                <strong>Facebook e Instagram (Meta):</strong>
                <ul>
                    <li>
                    Se clicchi sui pulsanti per visitare la nostra pagina Facebook o Instagram, Meta potrebbe
                    memorizzare cookie sul tuo dispositivo per analizzare il tuo comportamento online.
                    </li>
                    <li>
                    Maggiori informazioni:
                    <a
                        href="https://www.facebook.com/policies/cookies/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Meta (Facebook) Cookie Policy
                    </a>
                    </li>
                </ul>
                </li>
                <li>
                <strong>X (Twitter):</strong>
                <ul>
                    <li>
                    Se interagisci con il pulsante per accedere al nostro profilo su X (precedentemente
                    Twitter), la piattaforma potrebbe utilizzare cookie per tracciare l'attività.
                    </li>
                    <li>
                    Maggiori informazioni:
                    <a
                        href="https://help.twitter.com/it/rules-and-policies/twitter-cookies"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Twitter Cookie Policy
                    </a>
                    </li>
                </ul>
                </li>
                <li>
                <strong>Telegram:</strong>
                <ul>
                    <li>
                    Telegram utilizza principalmente cookie per garantire il funzionamento della sua
                    piattaforma, ma non raccoglie informazioni per scopi pubblicitari.
                    </li>
                    <li>
                    Maggiori informazioni:
                    <a
                        href="https://telegram.org/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Telegram Privacy Policy
                    </a>
                    </li>
                </ul>
                </li>
            </ol>

            <h2>Come gestire i cookie?</h2>
            <p>
                Puoi gestire o bloccare i cookie di terze parti utilizzando le impostazioni del tuo browser. Di
                seguito trovi i link alle istruzioni per i principali browser:
            </p>
            <ul>
                <li>
                <a
                    href="https://support.google.com/chrome/answer/95647?hl=it"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Google Chrome
                </a>
                </li>
                <li>
                <a
                    href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Mozilla Firefox
                </a>
                </li>
                <li>
                <a
                    href="https://support.apple.com/it-it/guide/safari/sfri11471/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Safari
                </a>
                </li>
                <li>
                <a
                    href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Microsoft Edge
                </a>
                </li>
            </ul>

            <h2>Consenso per i cookie di terze parti</h2>
            <p>
                Ti informiamo che, cliccando sui pulsanti dei social media presenti sul nostro sito, il tuo
                consenso sarà richiesto e gestito direttamente dalle rispettive piattaforme. Il nostro sito non
                memorizza né accede ai cookie generati da questi servizi.
            </p>

            <h2>Contatti</h2>
            <p>
                Se hai domande relative a questa Cookie Policy, puoi contattarci ai seguenti recapiti:
            </p>
            <ul>
                <li><strong>Email:</strong> <a href="mailto: pattonord@gmail.com" className="a">pattonord@gmail.com</a> </li>
                <li><strong>Telefono:</strong> <a href="https://wa.me/393518101018" className="a">351 8101018</a> </li>
            </ul>
        </section>

        <Footer />
    </div>
    );


};

export default CookiePolicy;