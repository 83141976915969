import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Tesserati from './pages/Tesserati';
import Home2 from './pages/Home2'
import InformativaPrivacy from './pages/InformativaPrivacy'
import CookiePolicy from './pages/CookiePolicy';
import Manifesti from './pages/Manifesti';

function App() {
  return (
    <Router>
      <Routes>
        {/* Definisci le rotte per ogni pagina */}
        <Route path="/" element={<Home2 />} />
        <Route path="/tesserati" element={<Tesserati />} />
        <Route path="/InformativaPrivacy" element={<InformativaPrivacy />} />
        <Route path="/CookiePolicy" element={<CookiePolicy />} />
        <Route path="Manifesti" element={<Manifesti />} />
        {/* Per gestire il fallback per una pagina non trovata */}
        <Route path="*" element={<h2>404 - Pagina non trovata</h2>} />
      </Routes>
    </Router>
  );
}


export default App;
