import React from "react";
import Navbar2 from '../components/Navbar2';
import Footer from '../components/Footer';
import '../styles/InformativaPrivacy.css'

const InformativaPrivacy = () =>{

    return(
        <div>
            <Navbar2 />
            <div className="paddingDiv">
            <h1>
                Informativa e Privacy
            </h1>
            <p>

                Patto per il Nord rispetta la tua privacy e si impegna a proteggere i dati personali degli utenti che interagiscono con noi. <br /> <br />
                Questa informativa descrive come raccogliamo, utilizziamo, proteggiamo e gestiamo i tuoi dati personali in conformità con il Regolamento (UE) 2016/679 (GDPR) e le normative vigenti.<br /> <br />

                <h2>1. Titolare del Trattamento dei Dati </h2><br /> <br />

                Il Titolare del trattamento dei dati è Patto per il Nord<br /><br />
                Per qualsiasi domanda relativa al trattamento dei dati personali, puoi contattarci all'indirizzo email pattonord@gmail.com <br /> <br />

                <h2>2. Tipologia di Dati Raccolti</h2><br /> <br />

                Patto per il Nord raccoglie e tratta i seguenti dati personali:<br /> <br />

                Dati identificativi: nome, cognome, data di nascita, ecc. (vedi dati richiesti nel form tesseramento).<br /> <br />

                Dati di contatto: indirizzo email, numero di telefono, ecc. (vedi dati richiesti nel form tesseramento).<br /> <br />
                
                Dati di navigazione: Raccogliamo metadati come l'indirizzo IP e il timestamp al momento della compilazione del form tesseramento per garantire la sicurezza, 
                tracciare l'origine della richiesta e dimostrare il consenso fornito dall'utente<br /> <br />


                <h2>3. Finalità e Base Giuridica del Trattamento</h2><br /> <br />

                I dati personali raccolti vengono trattati per le seguenti finalità:<br /> <br />

                Gestione della comunicazione: per rispondere a richieste di informazioni e assistenza da parte degli utenti.<br /><br />

                Tesseramento.

                Invio di comunicazioni e newsletter: previo consenso esplicito, invio di informazioni relative alle attività di Patto per il Nord, 
                aggiornamenti su eventi,iniziative e attività dell’organizzazione.<br /><br />

                Analisi statistica e miglioramento del sito: per migliorare l’esperienza utente e ottimizzare il contenuto del sito web.<br /><br />


                La base giuridica del trattamento è il consenso dell’utente, l'adempimento degli obblighi di legge e l’interesse legittimo del Titolare.<br /><br />

                <h2>4. Modalità di Trattamento e Sicurezza</h2><br /><br />

                Il trattamento dei dati avviene sia in modalità elettronica sia in formato cartaceo. Patto per il Nord adotta misure di sicurezza adeguate 
                per garantire la protezione dei dati personali contro accessi non autorizzati, perdita, distruzione o trattamento illecito.<br /><br />

                <h2>5. Comunicazione e Trasferimento dei Dati</h2><br /><br />

                I tuoi dati personali non saranno comunicati a terzi senza il tuo consenso, ad eccezione di soggetti che forniscono servizi tecnici e di supporto 
                per la gestione del sito e delle comunicazioni. Patto per il Nord garantisce che i soggetti terzi coinvolti rispettino le normative vigenti in materia di protezione dei dati.<br /><br />

                <h2>6. Conservazione dei Dati</h2><br /><br />

                I dati personali raccolti saranno conservati per il tempo strettamente necessario a conseguire le finalità sopra descritte e comunque 
                non oltre il periodo previsto dalle normative vigenti. In particolare:<br /><br />

                Dati di contatto per comunicazioni: saranno conservati fino a revoca del consenso.<br /><br />

                Dati di navigazione: saranno conservati per il tempo stabilito dalla nostra politica sui cookie.<br /><br />


                <h2>7. Diritti dell'Interessato</h2><br /><br />

                In conformità con il GDPR, hai il diritto di:<br /><br />

                Accedere ai tuoi dati personali per verificarne la correttezza e riceverne copia.<br /><br />

                Richiedere la rettifica o l'aggiornamento dei tuoi dati personali.<br /><br />

                Richiedere la cancellazione dei dati personali nei casi previsti dalla legge.<br /><br />

                Opporsi al trattamento o richiedere la limitazione del trattamento dei tuoi dati personali.<br /><br />

                Richiedere la portabilità dei dati.<br /><br />


                Per esercitare questi diritti, contattaci all'indirizzo pattonord@gmail.com <br /><br />
                È inoltre possibile presentare reclamo all’autorità di controllo, il Garante per la protezione dei dati personali.<br /><br />

                <h2>8. Modifiche all'Informativa</h2><br /><br />

                Patto per il Nord si riserva il diritto di aggiornare questa informativa per adeguarla a eventuali modifiche legislative 
                o a nuove modalità di trattamento dei dati personali.<br /><br />
                Sarà nostra cura informarti di eventuali aggiornamenti.<br /><br />
            </p>
            </div>
            <Footer />
        </div>
        );
};

export default InformativaPrivacy;