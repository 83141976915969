import React from "react";
import "../styles/HomeProvvisoria.css";

const ManifestiHome = () => {
  return (
    <div class="image-container">
  <a href="/Manifesti">
    <img src="../assets/images/Man.png" alt="Manifesti e Volantini" />
  </a>
    </div>
  );
};

export default ManifestiHome;
