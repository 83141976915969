const provinceTutte = [
    { label: "Estero", options: ["Estero"]},
    { label: "Lombardia", options: ["Milano", "Bergamo", "Brescia", "Como", "Cremona", "Lecco", "Lodi", "Mantova", "Monza e Brianza", "Pavia", "Sondrio", "Varese"] },
    { label: "Veneto", options: ["Venezia", "Belluno", "Padova", "Rovigo", "Treviso", "Verona", "Vicenza"] },
    { label: "Piemonte", options: ["Torino", "Alessandria", "Asti", "Biella", "Cuneo", "Novara", "Verbano-Cusio-Ossola", "Vercelli"] },
    { label: "Liguria", options: ["Genova", "Imperia", "La Spezia", "Savona"] },
    { label: "Trentino-Alto Adige", options: ["Bolzano", "Trento"] },
    { label: "Friuli Venezia Giulia", options: ["Gorizia", "Pordenone", "Trieste", "Udine"] },
    { label: "Valle d'Aosta", options: ["Aosta"] },
    { label: "Emilia-Romagna", options: ["Bologna", "Ferrara", "Forlì-Cesena", "Modena", "Parma", "Piacenza", "Ravenna", "Reggio Emilia", "Rimini"] },
    { label: "Toscana", options: ["Arezzo", "Firenze", "Grosseto", "Livorno", "Lucca", "Massa-Carrara", "Pisa", "Pistoia", "Prato", "Siena"] },
    { label: "Marche", options: ["Ancona", "Ascoli Piceno", "Fermo", "Macerata", "Pesaro e Urbino"] },
    { label: "Abruzzo", options: ["L'Aquila", "Teramo", "Pescara", "Chieti"] },
    { label: "Basilicata", options: ["Potenza", "Matera"] },
    { label: "Calabria", options: ["Catanzaro", "Cosenza", "Crotone", "Reggio Calabria", "Vibo Valentia"] },
    { label: "Campania", options: ["Avellino", "Benevento", "Caserta", "Napoli", "Salerno"] },
    { label: "Lazio", options: ["Frosinone", "Latina", "Rieti", "Roma", "Viterbo"] },
    { label: "Molise", options: ["Campobasso", "Isernia"] },
    { label: "Puglia", options: ["Bari", "Barletta-Andria-Trani", "Brindisi", "Foggia", "Lecce", "Taranto"] },
    { label: "Sardegna", options: ["Cagliari", "Nuoro", "Oristano", "Sassari", "Sud Sardegna"] },
    { label: "Sicilia", options: ["Agrigento", "Caltanissetta", "Catania", "Enna", "Messina", "Palermo", "Ragusa", "Siracusa", "Trapani"] },
    { label: "Umbria", options: ["Perugia", "Terni"] }
];

export default provinceTutte;
