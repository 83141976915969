import React from 'react';
import Navbar2 from '../components/Navbar2';
import Footer from '../components/Footer';
import HomeProvvisoria from '../components/HomeProvvisoria'
import CookiePopup from '../components/CookiePopup';
import ManifestiHome from '../components/ManifestiHome';


const Home = () => {
  return (
    <div>
      <Navbar2 />
      <HomeProvvisoria />
      <ManifestiHome />
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default Home;
