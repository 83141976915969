import React, { useState, useEffect } from 'react';
import comuni from '../utils/comuni_codici.json'; 
import '../styles/ContactFormTesseramento.css';

const mesiCodici = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T'];

const CodiceFiscaleCalculator = ({ nome, cognome, dataNascita, natoA, onCodiceFiscaleChange }) => {
  const [codiceFiscale, setCodiceFiscale] = useState('');
  const [isEditable, setIsEditable] = useState(false); 
  
  // Funzione per trovare il codice catastale dal JSON
  const getCodiceCatastale = (comune) => {
    const foundComune = comuni.find((c) => c.Comune.toLowerCase() === comune.toLowerCase());
    return foundComune ? foundComune.CodiceCatastale : null;
  };

  // Funzione per calcolare il carattere di controllo
  const calculateControlChar = (partialCodice) => {
    const oddValues = {
      0: 1, 1: 0, 2: 5, 3: 7, 4: 9, 5: 13, 6: 15, 7: 17, 8: 19, 9: 21,
      A: 1, B: 0, C: 5, D: 7, E: 9, F: 13, G: 15, H: 17, I: 19, J: 21,
      K: 2, L: 4, M: 18, N: 20, O: 11, P: 3, Q: 6, R: 8, S: 12, T: 14,
      U: 16, V: 10, W: 22, X: 25, Y: 24, Z: 23
    };

    const evenValues = {
      0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9,
      A: 0, B: 1, C: 2, D: 3, E: 4, F: 5, G: 6, H: 7, I: 8, J: 9,
      K: 10, L: 11, M: 12, N: 13, O: 14, P: 15, Q: 16, R: 17, S: 18, T: 19,
      U: 20, V: 21, W: 22, X: 23, Y: 24, Z: 25
    };

    let sum = 0;

    for (let i = 0; i < partialCodice.length; i++) {
      const char = partialCodice[i];
      sum += i % 2 === 0 ? oddValues[char] : evenValues[char];
    }

    return String.fromCharCode((sum % 26) + 65); // Aggiungi 65 per ottenere il carattere ASCII
  };

  // Funzione principale per calcolare il codice fiscale
  const calcolaCodiceFiscale = () => {
    if (!nome || !cognome || !dataNascita || !natoA) {
      setCodiceFiscale('');
      return;
    }

    const codiceCatastale = getCodiceCatastale(natoA);
    if (!codiceCatastale) {
      setCodiceFiscale('');
      return;
    }

    const [anno, mese, giorno] = dataNascita.split('-');

    const consonanti = (str) => str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '');
    const vocali = (str) => str.replace(/[^AEIOU]/gi, '');
    const calcolaParte = (str, isNome = false) => {
      const cons = consonanti(str);
      if (isNome && cons.length > 3) {
        return (cons[0] + cons[2] + cons[3]).toUpperCase();
      }
      const parte = (cons + vocali(str)).slice(0, 3).toUpperCase();
      return parte.padEnd(3, 'X');
    };

    const cognomeCodice = calcolaParte(cognome);
    const nomeCodice = calcolaParte(nome, true);
    const meseCodice = mesiCodici[parseInt(mese, 10) - 1];
    const giornoCodice = (parseInt(giorno, 10) + (parseInt(giorno, 10) < 10 ? 40 : 0))
      .toString()
      .padStart(2, '0');

    const partialCodice = `${cognomeCodice}${nomeCodice}${anno.slice(-2)}${meseCodice}${giornoCodice}${codiceCatastale}`;
    const controlChar = calculateControlChar(partialCodice);

    const codiceFiscaleCompleto = `${partialCodice}${controlChar}`;
    setCodiceFiscale(codiceFiscaleCompleto);
    onCodiceFiscaleChange(codiceFiscaleCompleto);
  };

  useEffect(() => {
    if (!isEditable) {
      calcolaCodiceFiscale();
    }
  }, [nome, cognome, dataNascita, natoA, isEditable]); 

  return (
    <div className="input-group">
      <label>Codice Fiscale:</label>
      <input
        type="text"
        value={codiceFiscale}
        readOnly={!isEditable}
        onChange={(e) => {
          const nuovoCodiceFiscale = e.target.value;
          setCodiceFiscale(nuovoCodiceFiscale);
          onCodiceFiscaleChange(nuovoCodiceFiscale); 
        }}
      />
      <div>
        <label>
          <input
            type="checkbox"
            checked={isEditable}
            onChange={(e) => setIsEditable(e.target.checked)}
          />
          Modifica manualmente se sbagliato
        </label>
      </div>
    </div>
  );
};

export default CodiceFiscaleCalculator;

