import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar2.css';


const Navbar2 = () => {
  const [isTransparent, setIsTransparent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsTransparent(window.scrollY > 30);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <header id="navbar" className={`navbar ${isTransparent ? 'transparent' : ''} ${isOpen ? 'open' : ''}`}>
     

      <div className="logo">
        <Link to="/"><img src="/assets/images/logo.png" alt="Logo" /></Link>
      </div>

    </header>
  );
};

export default Navbar2;








